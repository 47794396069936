<template>
  <div>
    <v-select v-if="fieldTemplates" clearable :items="fieldTemplates.map(t => t.type)" label="בחר סוג שדה חדש"
      v-model="selectedTemplateType"></v-select>
    <v-btn v-if="this.selectedTemplateType" @click="addNewField">הוסף</v-btn>
    <div class="d-flex flex-wrap">

      <v-card  v-for="(component, index) in components" :key="index" class="mx-5 my-5 elevation-9"
        :style="!component.id ? 'max-width: 80vw; background-color: #e0e0e0;' : 'max-width: 80vw;'">
        <v-card-title>{{ component.type }}</v-card-title>
        <v-card-text>
          <v-text-field label="Name" v-model="component.name"></v-text-field>
          <v-checkbox label="Show" v-model="component.toShow"></v-checkbox>
          <div v-for="(field, fieldName) in component.fields" :key="fieldName">
            <v-text-field :label="fieldName" v-model="field.value"></v-text-field>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="saveOrUpdateData(component)">שמור</v-btn>
          <v-btn @click="deleteData(component, index)">מחק</v-btn>
          <v-btn v-if="index === 0" @click="clearNewField">נקה</v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>
  
<script>
import fire from "@/main";

export default {
  name: "NewPostComponents",
  data() {
    return {
      components: [],
      fieldTemplates: [],
      selectedTemplateType: null
    }
  },
  async mounted() {
    // Load created fields
    let ref = fire.database().ref('admin/fields/created_fields/addRecord');
    ref.on('value', (snapshot) => {
      let items = snapshot.val();
      let newState = [];
      for (let item in items) {
        newState.push({
          id: item,
          ...items[item]
        });
      }
      this.components = newState;
    });

    // Load field templates
    let templateRef = fire.database().ref('admin/fields/field_types/addRecord');
    templateRef.on('value', (snapshot) => {
      let templates = snapshot.val();
      let newTemplates = [];
      for (let template in templates) {
        newTemplates.push(templates[template]);
      }
      this.fieldTemplates = newTemplates;
    });
  },
  methods: {
    addNewField() {
      if (!this.selectedTemplateType) {
        // Предупреждение, если шаблон не выбран
        alert("Please select a field template first.");
        return;
      }

      const template = this.fieldTemplates.find(t => t.type === this.selectedTemplateType);
      if (template) {
        const clonedTemplate = JSON.parse(JSON.stringify(template));
        const newComponent = {
          type: clonedTemplate.type,
          name: "",
          toShow: false,
          fields: {}
        };

        for (let key in clonedTemplate.fields) {
          newComponent.fields[key] = {
            type: typeof clonedTemplate.fields[key],  // или просто 'text', если это всегда текст
            value: ''
          };
        }

        this.components.unshift(newComponent); // Add new component to the start of the list
      }
    },
    saveOrUpdateData(component) {
      if (component.id) {
        const isConfirmed = window.confirm('Вы действительно хотите обновить это поле?');
        if (isConfirmed) {
          fire.database().ref(`admin/fields/created_fields/addRecord/${component.id}`).update(component);
        }
      } else {
        const newRef = fire.database().ref('admin/fields/created_fields/addRecord').push(component);
        component.id = newRef.key; // Присваиваем ID компоненту

        // Обновляем запись в базе данных с новым id
        newRef.update({ id: newRef.key });
      }
    },
    clearNewField() {
      if (this.components.length > 0 && !this.components[0].id) { // Проверяем, что первый компонент является новым (не имеет id)
        const newField = this.components[0];
        newField.name = "";
        newField.toShow = false;
        for (let key in newField.fields) {
          newField.fields[key].value = ""; // Очищаем все поля
        }
      }
    },
    deleteData(component, index) {
      if (index === 0 && !component.id) {  // Если это первый элемент и у него нет id (т.е. это новый элемент)
        this.clearNewField();
        if (this.components.length > 0) {
          this.components.splice(0, 1); // Удаляем первый элемент из массива
        }
      } else if (component.id) {  // Если у элемента есть id
        const isConfirmed = window.confirm('Вы действительно хотите удалить это поле?');
        if (isConfirmed) {
          fire.database().ref(`admin/fields/created_fields/addRecord/${component.id}`).remove();
        }
      }
    }

  }
}
</script>
